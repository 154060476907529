import React, { Fragment, useEffect, useState } from "react";
import { SubNav, Loader, useAPI, Grid, Stat, Card } from "components/lib";
import { useParams } from "react-router-dom";
import { StylesManager, Model } from "survey-core";
import { SurveyPDF } from "survey-pdf";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
const Axios = require("axios");

StylesManager.applyTheme("defaultV2");

export function AdminResponseView(props) {
  const params = useParams();
  const response = useAPI(`/api/response/${params.id}`);
  const verification = useAPI(`/api/verification/${params.verification_id}`);
  const [navBarItems, setNavBarItems] = useState([]);
  const [formData, setFormData] = useState([]);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    async function setUpNavBarItems() {
      const verificationInfo = verification.data.verification[0];
      let updatedNavBarItems = [];

      if (verificationInfo.sanctions_results) {
        updatedNavBarItems.push(
          {
            label: "KYC",
            link: `/verification/${params.verification_id}`,
          },
          {
            label: "Sanctions",
            link: `/verification/sanctions/${params.verification_id}`,
          }
        );
      }

      if (verificationInfo.adverse_media_results) {
        updatedNavBarItems.push({
          label: "Adverse Media",
          link: `/verification/adverse/${params.verification_id}`,
        });

        if (updatedNavBarItems.length === 1) {
          updatedNavBarItems = [
            {
              label: "KYC",
              link: `/verification/${params.verification_id}`,
            },
            ...updatedNavBarItems,
          ];
        }
      }

      if (verificationInfo.response_id) {
        updatedNavBarItems.push({
          label: "Response",
          link: `/verification/${params.verification_id}/response/${params.id}`,
        });
      }

      setNavBarItems(updatedNavBarItems);
    }

    if (!verification.loading && verification.data) {
      setUpNavBarItems();
    }
  }, [verification]);

  useEffect(async () => {
    const getFormData = async () => {
      const formResponse = await Axios.get(
        "/api/form/" + response?.data?.[0]?.form_id
      );
      return formResponse;
    };

    if (!response.loading && response.data) {
      const formResponse = await getFormData();
      setFormData(formResponse?.data?.data?.[0]);
      setResponseData(response.data?.[0]?.data);
    }
  }, [response]);

  useEffect(() => {
    if (response.data?.[0]?.created_at) {
      const date = new Date(response.data?.[0]?.created_at);
      setCreatedAt(date.toLocaleDateString());
    }

    if (response.data?.[0]?.updated_at) {
      const date = new Date(response.data?.[0]?.updated_at);
      setUpdatedAt(date.toLocaleDateString());
    }
  }, [response]);

  useEffect(() => {
    // check and get file questions
    if (formData || response.data?.[0]?.active === true) {
      const surveyTemp = new Model(formData?.data);
      const fileQuestions = [];
      console.log("surveyTemp", surveyTemp);
      console.log("formData", formData);
      surveyTemp.getAllQuestions().forEach((q) => {
        if (q.getType() === "file") {
          fileQuestions.push(q.name);
        }
      });
      console.log("fileQuestions", fileQuestions);

      if (fileQuestions.length === 0) {
        setLoading(false);
        return;
      }

      let updatedReponseData = response.data?.[0]?.data;
      console.log('updatedReponseData', updatedReponseData)

      if (!updatedReponseData || Object.keys(updatedReponseData).length === 0) {
        setLoading(false);
        return;
      }

     console.log('fileQuestions', fileQuestions) 

      const fileQuestionsWithFiles = fileQuestions.filter(
        (fileQuestion) => updatedReponseData[fileQuestion]?.length > 0
      );

      console.log("fileQuestionsWithFiles", fileQuestionsWithFiles);

      fileQuestionsWithFiles.forEach((fileQuestion) => {
        updatedReponseData[fileQuestion].forEach(async (file) => {
          console.log("file", file);
          // send to dl and get file
          const fileResponse = await Axios.get(
            `/api/utility/gcp/download?content=${file.key}&name=${file.name}`
          );
          console.log("fileResponse", fileResponse);
          // set file to file.content
          file.content = fileResponse.data.blob;
          setFiles([...files, file]);
        });
      });

      console.log("updatedReponseData", updatedReponseData);
      setResponseData(updatedReponseData)
      setLoading(false);

      // const updatedFileQuestions = fileQuestions.map((fileList) => {
      //   fileList.forEach(async (file) => {
      //     console.log("file", file);
      //     // send to dl and get file
      //     const fileResponse = await Axios.get(
      //       `/api/utility/gcp/download?content=${file.key}`
      //     );
      //     console.log("fileResponse", fileResponse);
      //     // set file to file.content
      //     return {
      //       ...file,
      //       content: fileResponse.blob,
      //     };
      //   });
      // });

      // console.log("updatedFileQuestions", updatedFileQuestions);
    }
  }, [formData, response]);

  const exportToPdfOptions = {
    fontSize: 12,
    commercial: true,
  };

  const savePdf = function (surveyData, fieldData, resultData) {
    const filename = `${response?.data?.[0]?.id}.pdf`;
    console.log("filename", filename);
    fieldData.pages.forEach((p) => {
      if (p.elements) {
        p.elements.forEach((e) => {
          if (e.type === "comment") {
            e.type = "html";
            e.html = `<h3>${e.title}</h3> ${resultData[e.name]}`;
          }
          // if (e.type === "file") {
          //   e.type = "html";
          //   e.html = `<h3>${e.title}</h3>
          //   <p><a href=${resultData[e.name]?.[0]?.content}>${
          //     resultData[e?.name]?.[0]?.name
          //   }</a></p>
          //   <img src=${resultData[e.name]?.[0]?.content} />`;
          // }
        });
      }

      // const blob = new Blob([Buffer.from('base64',files[0].content.split('base64,')[1]], { type: files[0].type });

    //   const blob = new Blob([Buffer.from(files[0].content.split('base64,')[1]), 'base64'], { type: files[0].type });

    // // Create a link to the blob
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = files[0].name; // The name of the downloaded file
    // document.body.appendChild(link);

    // // Programmatically click the link to trigger the download
    // link.click();

    // // Revoke the object URL and remove the link from the document
    // window.URL.revokeObjectURL(link.href);
    // document.body.removeChild(link);
    });
    const surveyPdf = new SurveyPDF(fieldData, exportToPdfOptions);
    surveyPdf.data = surveyData;
    surveyPdf.save(filename);
  };

  const survey = new Model(response.data?.[0]?.form_data);
  console.log("data", response.data?.[0]?.data);
  survey.data = responseData;
  survey.mode = "display";

  survey.onDownloadFile.add((sender, options) => {
    // console.log("onDownloadFile", sender, options);
    // Axios.get("/api/utility/download/" + id, {
    //   params: { url: options.content },
    // }).then((res) => {
    //   console.log("download", res);
    //   const fileName = options.name;
    //   const fileUrl = res?.data?.data?.url;

    //   options.callback("success", fileUrl);
    // });
    console.log("onDownloadFile", sender, options);
  });


  survey.addNavigationItem({
    id: "pdf-export",
    title: "Save as PDF",
    action: () =>
      savePdf(
        survey.data,
        response.data?.[0]?.form_data,
        response.data?.[0]?.data
      ),
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <SubNav items={navBarItems} />

      <Grid cols="4">
        <Stat
          loading={response.loading}
          value={response.data?.[0]?.active === true ? "Yes" : "No"}
          label="active"
          icon="activity"
        />
        <Stat
          // loading={formData?.name}
          value={formData?.name}
          label="form name"
          icon="tag"
        />
        <Stat
          loading={response.loading}
          value={createdAt}
          label="created at"
          icon="calendar"
        />
        <Stat
          loading={response.loading}
          value={updatedAt}
          label="updated at"
          icon="calendar"
        />
      </Grid>
      <Card loading={response?.loading}>
        {!response?.loading && <Survey model={survey} />}
      </Card>
    </Fragment>
  );
}
